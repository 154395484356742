import React from 'react';
import { Skeleton } from 'antd';
import { ExtensionProps } from './types';

const LazySecuritySettings = React.lazy(() => import('./SecuritySettings'));

export const AsyncLazySecuritySettings: React.FC = () => (
	<React.Suspense fallback={<Skeleton />}>
		<LazySecuritySettings />
	</React.Suspense>
);

const LazyUserSecurityAlertSettings = React.lazy(
	() => import('./UserSecurityAlertSettings')
);

export const AsyncLazyUserSecurityAlertSettings: React.FC<ExtensionProps> = ({ app }) => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyUserSecurityAlertSettings app={app} />
	</React.Suspense>
);
