import * as React from 'react';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import {
	AsyncLazySecuritySettings,
	AsyncLazyUserSecurityAlertSettings,
} from './extensions';

export function setup(app: PiletApi) {
	app.sf.registerSfApiConfigHandler(Api.configure as any);

	app.registerExtension('urn:sfextslot:sharefile-appshell:threatalert-mgt-pilet', () => (
		<AsyncLazySecuritySettings />
	));

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:threatalert-mgt-pilet:user-preferences',
		() => <AsyncLazyUserSecurityAlertSettings app={app} />
	);
}
